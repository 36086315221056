import React from 'react';
import {Link} from 'gatsby'
import currencySymbolMap from 'currency-symbol-map';


const CartHeader  = ({item, removeFromCart, decrementQty, incrementQty}) => (
    <>
        <div className="setting-title">
            <div className="row">
                <div className="col-3">
                    <Link to={`/products/${item.variant.product.handle}`}>
                        <img alt={item.title} className="w-100" src={`${item.variant.image.src}`} />
                    </Link>
                </div>
                <div className="col-7">
                    <Link to={`/products/${item.variant.product.handle}`}><h5 className="text-info"><b>{item.title}</b></h5></Link>
                    <h5><b>{currencySymbolMap(item.variant.priceV2.currencyCode)}{Number.parseFloat(item.variant.priceV2.amount).toFixed(2)}</b></h5>
                    <button className="btn btn-xs bg-transparent p-0 text-gray" onClick={removeFromCart} ><small>Remove</small></button>
                </div>
                <div className="col-2">
                    <div className="row text-center">
                        <div className="col">
                            {/* disabled={(item.qty >= item.stock)? true : false} */}
                            <button className="btn bg-transparent p-0" data-type="plus" onClick={incrementQty}>
                                <span className="fa fa-angle-up fa-2x"></span>
                            </button>
                        </div>
                        <div className="col">{item.quantity}</div>
                        <div className="col">
                            <button type="button" className="btn bg-transparent p-0" onClick={decrementQty} data-type="minus" data-field="" disabled={item.quantity === 1}>
                                <span className="fa fa-angle-down fa-2x"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)


        


export default CartHeader;
