import React, {Component} from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'
import SocialMedia from '../social-media'
import PaymentMethods from '../payment-methods'
import SubscribeForm from '../subscribe-form'
import {Detector} from 'react-detect-offline'

export default class Footer extends Component {

    handleConnectionState = (backOnline) => {
        if(backOnline) {
          document.location.reload(true)
        }
    }
    
    render() {   
        return (
            <StaticQuery
                query={query}
                render={(data) => (
                    <footer className="footer-light mt-5">
                        <div className="light-layout">
                            <div className="container">
                                <section className="small-section border-section border-top-0">
                                    <div className="row">
                                        <div className="col-lg-6 footer-theme">
                                            <div className="sub-title">
                                                <div className="footer-contant">
                                                    <ul>
                                                        {data.file.childMarkdownRemark.frontmatter.footerMenu.map((item, key) => <li key={key}><Link to={item.url}>{item.label}</Link></li>)}
                                                        <li><Link to="/search">Search</Link></li>
                                                        <li><Link to="/contact">Contact</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <SubscribeForm/>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <section className="section-b-space light-layout pt-0 pb-4">
                            <div className="container">
                                <div className="row footer-theme partition-f">
                                    {data.file.childMarkdownRemark.frontmatter.socialLinksVisible && (
                                        <div className="col">
                                            <SocialMedia />
                                        </div>
                                    )}

                                    {data.file.childMarkdownRemark.frontmatter.paymentMethodsVisible && (
                                        <div className="col">
                                            <PaymentMethods />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </section>
                        <div className="sub-footer ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-md-6 col-sm-12">
                                        <div className="footer-end">
                                            <p dangerouslySetInnerHTML={{__html: data.file.childMarkdownRemark.frontmatter.copyright}}></p>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-6 col-sm-12">
                                        <div className="payment-card-bottom">
                                            <ul>
                                                <li><span className="fab fa-cc-mastercard fa-3x fa-lg"></span></li>
                                                <li><span className="fab fa-cc-discover fa-3x fa-lg"></span></li>
                                                <li><span className="fab fa-cc-amex fa-3x fa-lg"></span></li>
                                                <li><span className="fab fa-cc-visa fa-3x fa-lg"></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Detector
                            onChange={this.handleConnectionState}
                            render={({ online }) => (
                                !online && (
                                    <div className="col-xl-12 col-md-12 col-sm-12 p-2" id="offline-bar">
                                        <p className="text-center text-white">You're currently browsing the store on <b>offline mode</b></p>
                                    </div>
                                )
                            )}
                            />
                    </footer>
                )}/>
            )
    }
}

const query = graphql`
  {
    file(base: {eq: "footer.md"}) {
      childMarkdownRemark {
        frontmatter {
          footerMenu {
            label
            url
          }
          paymentMethods {
            americanExpress
            applePay
            dinersClub
            jcb
            masterCard
            paypal
            visa
          }
          paymentMethodsVisible
          socialLinksVisible
          copyright
        }
      }
    }
  }
  
`