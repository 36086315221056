import React, {Component} from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image'
import {connect} from 'react-redux';
import currencySymbolMap from 'currency-symbol-map';
import { getShippingPrice } from '../lib';

import CartPage from './cart-header'
import { removeFromCart, updateCartItem } from '../../actions/cart';
import SidebarRight from './sidebar-right';

class CartContainer extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            items: this.props.frontstore.checkout !== null ? this.props.frontstore.checkout.lineItems : [],
            currency: this.props.frontstore.checkout !== null ? this.props.frontstore.checkout.currencyCode : null,
            discount: 0,
            subtotalPrice: this.props.frontstore.checkout !== null ? Number.parseFloat(this.props.frontstore.checkout.lineItemsSubtotalPrice.amount).toFixed(2) : 0.00,
            shippingPrice: this.props.frontstore.checkout !== null ? Number.parseFloat(getShippingPrice(this.props.frontstore.checkout.totalPrice)).toFixed(2) : 0.00,
            totalTax: this.props.frontstore.checkout !== null ? Number.parseFloat(this.props.frontstore.checkout.totalTax).toFixed(2) : 0.00,
            total: this.props.frontstore.checkout !== null ? Number.parseFloat(this.props.frontstore.checkout.totalPrice).toFixed(2) : 0.00,
            checkoutId: this.props.frontstore.checkout !== null ? this.props.frontstore.checkout.id : null
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.frontstore.success !== this.props.frontstore.success && nextProps.frontstore.success === true) {       
            const {checkout} = nextProps.frontstore 
            this.setState({ 
                items: checkout.lineItems,
                currency: checkout.currencyCode,
                subtotalPrice: Number.parseFloat(checkout.lineItemsSubtotalPrice.amount).toFixed(2),
                totalTax: Number.parseFloat(checkout.totalTax).toFixed(2),
                shippingPrice: Number.parseFloat(getShippingPrice(checkout.totalPrice)).toFixed(2),
                total: Number.parseFloat(checkout.totalPrice).toFixed(2),
                checkoutId: checkout.id,
                discount: Number.parseFloat(checkout.lineItemsSubtotalPrice.amount - checkout.subtotalPrice).toFixed(2)
            })
        }

        if(this.props.frontstore.success !== nextProps.frontstore.success && nextProps.frontstore.success === true && nextProps.frontstore.newItem === true) {
            this.props.openCart()
        }
    }

    render() {
     
     return (
            <li className="onhover-div mobile-cart">
                {this.state.items.length > 0 && <div className="cart-qty-cls">{this.state.items.length}</div>}
               
                <button className="btn bg-transparent" onClick={this.props.openCart}>
                    <svg aria-hidden="true" focusable="false" role="presentation" style={{display: 'inline-block',
                                                                                            width: '23px',
                                                                                            height: '23px',
                                                                                            verticalAlign: 'middle',
                                                                                            fill: 'currentColor'}} viewBox="0 0 37 40">
                        <path d="M36.5 34.8L33.3 8h-5.9C26.7 3.9 23 .8 18.5.8S10.3 3.9 9.6 8H3.7L.5 34.8c-.2 1.5.4 2.4.9 3 .5.5 1.4 1.2 3.1 1.2h28c1.3 0 2.4-.4 3.1-1.3.7-.7 1-1.8.9-2.9zm-18-30c2.2 0 4.1 1.4 4.7 3.2h-9.5c.7-1.9 2.6-3.2 4.8-3.2zM4.5 35l2.8-23h2.2v3c0 1.1.9 2 2 2s2-.9 2-2v-3h10v3c0 1.1.9 2 2 2s2-.9 2-2v-3h2.2l2.8 23h-28z"/>
                    </svg>
                </button>

                <SidebarRight closeCart={this.props.closeCart}> 
                    <div className="items-containers">
                        {this.state.items.map((item,index) => (
                            <CartPage 
                                key={index} 
                                item={item} 
                                removeFromCart={() => this.props.removeFromCart(this.state.checkoutId, [item.id])} 
                                decrementQty={() => this.props.updateLineitem(this.state.checkoutId, [{id: item.id, quantity: item.quantity - 1}], 'Decrement Qty')} 
                                incrementQty={() => this.props.updateLineitem(this.state.checkoutId, [{id: item.id, quantity: item.quantity + 1}], 'Added')} />))
                        }
                    </div>
                    {(this.state.items.length > 0) ?
                        <table className="col-12" id="cart-summary">
                            <tbody>
                                <tr>
                                    <td className="pt-3"><b>Subtotal :</b></td> 
                                    <td className="text-right">{currencySymbolMap(this.state.currency)}{this.state.subtotalPrice}</td>
                                </tr>
                                {eval(this.state.discount) !== 0 && (
                                    <tr>
                                        <td><b>Discount :</b></td> 
                                        <td className="text-right">-{currencySymbolMap(this.state.currency)}{this.state.discount}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td><b>Tax :</b></td> 
                                    <td className="text-right">{currencySymbolMap(this.state.currency)}{this.state.totalTax}</td>
                                </tr>
                                <tr>
                                    <td><b>Shipping :</b></td> 
                                    <td className="text-right">{currencySymbolMap(this.state.currency)}{this.state.shippingPrice}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}><hr className="m-0"/></td>
                                </tr>
                                <tr>
                                    <td><b>Total :</b></td> 
                                    <td className="text-right">{currencySymbolMap(this.state.currency)}{Number.parseFloat(eval(this.state.total +'+'+ this.state.shippingPrice)).toFixed(2)}</td>
                                </tr>
                                <tr className="buttons">
                                    <td colSpan={2}>
                                        <Link to={'/checkout'} onClick={(e) => this.props.closeCart()} className="btn btn-info btn-block p-3">checkout</Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                            :
                        <div className="text-center">
                            <h3>
                                <strong>Your Cart is Empty</strong>
                            </h3>
                            <h4>Explore more shortlist some items.</h4>
                        </div>
                    }
                </SidebarRight>
            </li>
        )
    }
}
const mapStateToProps = ({cartReducer}) => {
    return {
        frontstore: cartReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeFromCart: (checkoutId, removeLineItems) => dispatch(removeFromCart(checkoutId, removeLineItems)),
        updateLineitem: (checkoutId, lineItemToUpdate, operationType) => dispatch(updateCartItem(checkoutId, lineItemToUpdate, operationType))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);