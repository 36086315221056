import React, {Component} from 'react'
import Fuse from 'fuse.js'
import ProductListItem from '../../components/collection-settings/product-list-item'

const products = require('./products.json')

export default class SearchBox extends Component {

    constructor(props){
        super(props)

        this.state = {
            searchString: '',
            searchResult: [],
            searching: false
        }

        this.fuse = new Fuse(products, {
            isCaseSensitive: false,
            // includeScore: false,
            shouldSort: true,
            // includeMatches: true,
            // findAllMatches: false,
            minMatchCharLength: 2,
            // location: 0,
            // threshold: 0.2,
            // distance: 100,
            // useExtendedSearch: false,
            keys: [
              "node.title",
              "node.tags",
            ]
          });
    }

    handleChange = (e) => {
        const {target} = e
        if (target.name === 'search') {
            const searchResult = this.fuse.search(target.value)
            this.setState({searchResult, searchString: target.value})
        }
    }

    displaySearchResults = () => (
        this.state.searchResult.length === 0 ? (
            <div className="row">
                <div className="col text-center pt-5">
                    {this.state.searchString && (
                        <p>Your search for "{this.state.searchString}" did not yield any results.</p>
                    )}
                </div>
            </div>
        ): (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 pt-5 pb-3">
                        <h5>{this.state.searchResult.length} RESULT FOR "{this.state.searchString}"</h5>
                        <hr/>
                    </div>
                    {this.state.searchResult.map((data, index) => {
                        const product = data.item.node
                        return (
                            <div className="col-xl-3 col-md-6 col-grid-box" key={index}>
                                <ProductListItem product={product} key={index} simpleImage={true}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    )

    render() {
        return (
            <>
            <div className="row">
                <div className="col-md-6 offset-md-3 col-sm-12 col-xs-12">
                    <h3 className="text-center mb-4">SEARCH OUR SITE</h3>
                    <input type="text" className="form-control" placeholder="Search for products.." name="search" onChange={this.handleChange}/>
                </div>
            </div>
            {this.displaySearchResults()}
            </>
        )
    }
}