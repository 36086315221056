import React, {Component} from 'react';

class SidebarRight extends Component {

    render() {

        return (
            <div>
                {/* <a href="javascript:void(0)" onClick={() => this.openSetting()}>
                    <div className="setting-sidebar" id="setting-icon">
                        <div>
                            <i className="fa fa-cog" aria-hidden="true"></i>
                        </div>
                    </div>
                </a> */}                

                <div id="setting_box" className="setting-box">
                    <button className="overlay btn bg-transparent" onClick={() => this.props.closeCart()}></button>
                    <div className="setting_box_body">
                        <div onClick={() => this.props.closeCart()}>
                            <div className="sidebar-back text-left">
                                <svg width="38" height="11" viewBox="0 0 38 11"><title>Go back</title><path fillRule="evenodd" d="M5.665 0L1.063 4.469l-.001-.002L0 5.498v.004l1.062 1.031.001-.001L5.665 11 6.73 9.967 2.878 6.229h35.121V4.771H2.878l3.852-3.74z"></path></svg>
                            </div>
                        </div>
                        <h4 className="text-center cart-title">YOUR BAG</h4>
                        <div className="setting-body">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SidebarRight;
