import React, { Component } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
// import { withTranslate } from 'react-redux-multilingual'

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.navCloseRight = '-410px'
        this.state = {
            navClose: { right: '0px' }
        }
    }

    componentWillMount() {
        if(typeof window !== 'undefined') {
            if (window.innerWidth < 750) {
                this.navCloseRight = '-300px'
            } else if (window.innerWidth < 1199) {
                this.navCloseRight = '-410px'
            }
        }

        this.setState({ navClose: { right: this.navCloseRight } })
    }

    openNav() {
        this.setState({ navClose: { right: '0px' } })
    }
    closeNav() {
        this.setState({ navClose: { right: this.navCloseRight } })
    }

    // onMouseEnterHandler() {
    //     if (window.innerWidth > 1199) {
    //         document.querySelector("#main-menu").classList.add("hover-unset");
    //     }
    // }

    // handleSubmenu = (event) => {
    //     if (event.target.classList.contains('sub-arrow'))
    //         return;

    //     if(event.target.nextElementSibling.classList.contains('opensubmenu'))
    //         event.target.nextElementSibling.classList.remove('opensubmenu')
    //     else{
    //         document.querySelectorAll('.nav-submenu').forEach(function (value) {
    //             value.classList.remove('opensubmenu');
    //         });
    //         document.querySelector('.mega-menu-container').classList.remove('opensubmenu')
    //         event.target.nextElementSibling.classList.add('opensubmenu')
    //     }
    // }

    // handleMegaSubmenu = (event) => {
    //     if (event.target.classList.contains('sub-arrow'))
    //         return;
            
    //     if(event.target.parentNode.nextElementSibling.classList.contains('opensubmegamenu'))
    //         event.target.parentNode.nextElementSibling.classList.remove('opensubmegamenu')
    //     else{
    //         document.querySelectorAll('.menu-content').forEach(function (value) {
    //             value.classList.remove('opensubmegamenu');
    //         });
    //         event.target.parentNode.nextElementSibling.classList.add('opensubmegamenu')
    //     }
    // }

    render() {
        return (
            <StaticQuery 
                query={query}
                render={(data) => {
                    const {menu} = data.file.childMarkdownRemark.frontmatter
                    return (
                    <div>
                        <div className="main-navbar">
                            <div id="mainnav" >
                                <div className="toggle-nav" onClick={this.openNav.bind(this)} >
                                    <i className="fa fa-bars sidebar-bar"></i>
                                </div>
                                <ul className="nav-menu" style={this.state.navClose}>
                                    <li className="back-btn" onClick={this.closeNav.bind(this)} >
                                        <div className="mobile-back text-right">
                                            <span >Back</span>
                                            <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                                        </div>
                                    </li>
                                    {menu.map((item, key) => (
                                        <li key={key}>
                                            <Link to={item.url} onClick={this.closeNav.bind(this)}>
                                                {item.label}
                                            </Link>
                                        </li>
                                    ))}
                                    
                                    {/* <li>
                                        <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                            {('pages')}
                                            <span className="sub-arrow"></span>
                                        </Link>
                                        <ul className="nav-submenu">
                                            <li><Link to={`/pages/about-us`} >{('about_us')}</Link></li>
                                            <li><Link to={`/pages/404`} >404</Link></li>
                                            <li><Link to={`/pages/lookbook`} >{('lookbook')}</Link></li>
                                            <li><Link to={`/pages/login`} >{('login')}</Link></li>
                                            <li><Link to={`/pages/register`} >{('register')}</Link></li>
                                            <li><Link to={`/pages/search`} >{('search')}</Link></li>
                                            <li><Link to={`/pages/collection`} >{('collection')}</Link></li>
                                            <li><Link to={`/pages/forget-password`} >{('forget_password')}</Link></li>
                                            <li><Link to={`/pages/contact`} >{('contact')}</Link></li>
                                            <li><Link to={`/pages/dashboard`} >{('dashboard')}</Link></li>
                                            <li><Link to={`/pages/faq`} >{('FAQ')}</Link></li>
                                        </ul>
                                    </li>
                                    <li >
                                        <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                            {('blog')}
                                            <span className="sub-arrow"></span>
                                        </Link>
                                        <ul className="nav-submenu">
                                            <li><Link to={`/blog/blog-page`} >{('blog_left_sidebar')}</Link></li>
                                            <li><Link to={`/blog/right-sidebar`} >{('blog_right_sidebar')}</Link></li>
                                            <li><Link to={`/blog/details`} >{('blog_detail')}</Link></li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}}/>
        )
    }
}

const query = graphql`
{
    file(base: {eq: "nav-menu.md"}) {
      childMarkdownRemark {
        frontmatter {
          menu {
            label
            url
          }
        }
      }
    }
}
` 

export default NavBar;