import React, {Component} from 'react'
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { sendData } from '../../common-tools/SubmitToNetlifyCMS';

export default class SubscribeForm extends Component {

    constructor(props){
        super(props)

        this.state = {
            email: '',
            sending: false,
            isSent: false
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({sending: true})
        sendData("subscribeForm", {email: this.state.email}).then(res => {
            this.setState({sending: false, isSent: true})
        }).catch(err => {
            this.setState({sending: false})
        })
    }

    handleChange = e => this.setState({ [e.target.name]: e.target.value })

    render() {

        return (
            this.state.isSent ? (
                <div className="col text-center">
                    <i className="fa fa-check-circle fa-3x text-success mb-3"></i>
                    <h4>Successfully Subscribed!</h4>
                </div>
            ):(
                <LoadingOverlay style={this.state.sending ? { backgroundColor: '#eee' }: {}}> 
                    <Loader text="Please wait ..." loading={this.state.sending}/>
                    <form className="form-inline subscribe-form" name="subscribeForm" method="POST" data-netlify="true"
                        onSubmit={this.handleSubmit}>
                        <div className="form-group mx-sm-3">
                            <input type="email" className="form-control" name="email"
                                    placeholder="Enter your email" required value={this.state.email} onChange={this.handleChange}/>
                        </div>
                        <button type="submit" className="btn btn-solid">subscribe</button>
                    </form>
                </LoadingOverlay>
            )
        )
    }
}