import { createStore, applyMiddleware, compose } from 'redux';

// middlewares
import thunkMiddleware from 'redux-thunk'
// import logger from 'redux-logger'

// Import custom components
import rootReducer from '../reducers';

/**
 * Create a Redux store that holds the app state.
 */
// persistedState 
const store = createStore(rootReducer, compose(
    applyMiddleware(thunkMiddleware),
    typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : function (f) {
        return f;
    }
));


export default store;