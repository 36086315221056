import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
const SEO = ({ title, description, image, pathname }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const siteMetadata = data.file !== null ? data.file.childMarkdownRemark.frontmatter : null

      const seo = {
        title: title,
        description: description || siteMetadata.defaultDescription,
        image: `${siteMetadata.siteUrl}${image || siteMetadata.defaultImage.childImageSharp.fixed.src}`,
        url: `${siteMetadata.siteUrl}${pathname || "/"}`,
      }
      return (
        <>
          <Helmet title={seo.title || siteMetadata.defaultTitle} titleTemplate={seo.title ? `%s | ${siteMetadata.defaultTitle}`: null}>
            <html lang="en" />
            <meta name="description" content={seo.description} />
            {siteMetadata.keyWords && siteMetadata.keyWords.length > 0 && (
              <meta name="keywords" content={siteMetadata.keyWords.join(',')} />
            )}
            {seo.image && (
              <meta name="image" content={seo.image} />
            )}
            <meta property="og:type" content="website" />
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="300" />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {siteMetadata.twitterUsername && (
              <meta name="twitter:creator" content={siteMetadata.twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query SeoQuery {
    file(base: {eq: "site-metadata.md"}) {
      childMarkdownRemark {
        frontmatter {
          defaultTitle: title
          siteUrl
          keyWords
          defaultDescription: description
          defaultImage: image {
              childImageSharp {
                  fixed {
                      src
                  }
              }
          }
          author
          twitterUsername
        }
      }
    }
  }
  `