import React, {Component} from 'react'
import {StaticQuery, graphql} from 'gatsby'

export default class TopBar extends Component {
  
  render () {
    return(
    <StaticQuery
        query={query}
        render={(data) => {
            const {isVisible, message} = data.file.childMarkdownRemark.frontmatter
            return (
                isVisible ? <div className="top-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <p className="text-white text-center pt-sm-3 m-0 mb-sm-3">
                                  {message}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>:'')
        }}
        />
    )
  }
}

const query = graphql`
 {
    file(base: {eq: "message.md"}) {
      childMarkdownRemark {
        frontmatter {
          isVisible
          message
        }
      }
    }
 }
`