import React, { Component } from 'react';
// import { Link, NavLink} from 'react-router-dom';
// import { IntlActions } from 'react-redux-multilingual'
// import Pace from 'react-pace-progress'
// Import custom components
// import store from '../../../store';
import NavBar from './nav-bar';
// import SideBar from './side-bar';
import CartContainer from './CartContainer';
import TopBar from './top-bar';
import LogoImage from './logo';
import SearchBox from '../search';
// import SidebarRight from './sidebar-right';
// import {changeCurrency} from '../../../actions'
// import {connect} from "react-redux";

class Header extends Component {

    constructor(props) {
        super(props);

		this.state = {
			isLoading:false
		}
    }
    /*=====================
         Pre loader
         ==========================*/
    componentDidMount() {
        // setTimeout(function() {
        //     document.querySelector(".loader-wrapper").style = "display: none";
        // }, 2000);

        this.setState({ open: true });
    }

    componentWillMount(){
        if(typeof window !== 'undefined') {
            window.addEventListener('scroll', this.handleScroll);
        }
	}
    componentWillUnmount() {
        if(typeof window !== 'undefined') {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll = () => {
        let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 300) {
            if (window.innerWidth < 576) {
                document.getElementById("sticky").classList.remove('fixed');
            }else
            	document.getElementById("sticky").classList.add('fixed');
        } else {
            document.getElementById("sticky").classList.remove('fixed');
        }
    }

    // changeLanguage(lang) {
    //     store.dispatch(IntlActions.setLocale(lang))
	// }

    openNav() {
        var openmyslide = document.getElementById("mySidenav");
        if(openmyslide){
            openmyslide.classList.add('open-side')
		}
    }
    openSearch() {
        document.getElementById("search-overlay").style.display = "block";
    }

    closeSearch() {
        document.getElementById("search-overlay").style.display = "none";
    }

	load = ()=>{
		this.setState({isLoading: true});
		fetch().then(()=>{
			// deal with data fetched
			this.setState({isLoading: false})
		})
	};

	openCart = () => {
        document.getElementById("setting_box").classList.add('open-setting');
    }
    
    closeCart = () => {
        document.getElementById("setting_box").classList.remove('open-setting');
    }
	
	render() {

		return (
			<div>
				<header id="sticky" className="sticky">
					{/* {this.state.isLoading ? <Pace color="#27ae60"/> : null} */}
					<div className="mobile-fix-option"></div>
					{/*Top Header Component*/}
					<TopBar/>

					<div className="container">
						<div className="row">
							<div className="col-sm-12">
								<div className="main-menu">
									<div className="menu-left">
										{/* <div className="navbar">
											<button className="btn btn-light" onClick={this.openNav}>
												<div className="bar-style"> <i className="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
											</button>
											<SideBar/>
										</div> */}
										<div className="brand-logo mt-1">
                                            {/* logo={this.props.logoName} */}
											<LogoImage />
										</div>
									</div>
									<div className="menu-right pull-right">
										{/*Top Navigation Bar Component*/}
										<NavBar/>

										<div>
											<div className="icon-nav">
												<ul>
													<li className="onhover-div mobile-search">
														<div><span className="fa fa-search" onClick={this.openSearch}></span></div>
													</li>
													{/* <li className="onhover-div mobile-setting">
														<div><img src={`${process.env.PUBLIC_URL}/assets/images/icon/setting.png`} className="img-fluid" alt="" />
															<i className="fa fa-cog"></i></div>
														<div className="show-div setting">
															<h6>language</h6>
															<ul>
																<li><a href={null} onClick={() => this.changeLanguage('en')}>English</a> </li>
																<li><a href={null} onClick={() => this.changeLanguage('fn')}>French</a> </li>
															</ul>
															<h6>currency</h6>
															<ul className="list-inline">
																<li><a href={null} onClick={() => this.props.changeCurrency('€')}>euro</a> </li>
																<li><a href={null} onClick={() => this.props.changeCurrency('₹')}>rupees</a> </li>
																<li><a href={null} onClick={() => this.props.changeCurrency('£')}>pound</a> </li>
																<li><a href={null} onClick={() => this.props.changeCurrency('$')}>doller</a> </li>
															</ul>
														</div>
													</li> */}
													{/*Header Cart Component */}
													<CartContainer openCart={this.openCart} closeCart={this.closeCart} cartList={[]} total={0} removeFromCart={this.removeFromCart} />
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>

				<div id="search-overlay" className="search-overlay">
                    <div>
                        <span className="closebtn" onClick={this.closeSearch} title="Close Overlay">×</span>
                        <div className="overlay-content mt-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <SearchBox/>	
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

			</div>
			)
	}
}

// export default connect(null,
//     { changeCurrency }
// )(HeaderOne);

export default Header