import React, {Component} from 'react'
import {StaticQuery, graphql} from 'gatsby'

class SocialMedia extends Component {

    render () {

        return <StaticQuery 
            query={query}
            render={({file}) => file.childMarkdownRemark.frontmatter.social !== null ? (
                <div className="footer-social">
                    <ul>
                        {file.childMarkdownRemark.frontmatter.social.facebook && (
                            <li>
                                <a href={file.childMarkdownRemark.frontmatter.social.facebook} target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                        )}
                        {file.childMarkdownRemark.frontmatter.social.twitter && (
                            <li>
                                <a to={file.childMarkdownRemark.frontmatter.social.twitter} target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                        )}
                        {file.childMarkdownRemark.frontmatter.social.instagram && (
                            <li>
                                <a to={file.childMarkdownRemark.frontmatter.social.instagram} target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                            </li>
                        )}
                        {file.childMarkdownRemark.frontmatter.social.linkedin && (
                            <li>
                                <a to={file.childMarkdownRemark.frontmatter.social.linkedin} target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                            </li>
                        )}
                    </ul>
                </div>
            ): ''}
        />
    }
}

const query = graphql`
{
    file(base: {eq: "contact-info.md"}) {
        childMarkdownRemark{
            frontmatter {
                social {
                    facebook
                    twitter
                    instagram
                    linkedin
                }
            }
        }
    }
}
`

export default SocialMedia