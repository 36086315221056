import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import getSymbolFromCurrency from 'currency-symbol-map'
import {connect} from 'react-redux'
import { addToCart } from '../../actions/cart';

class ProductListItem extends Component {

    constructor(props){
        super(props)

        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            availableForSale: this.props.product.variants[0] !== null && this.props.product.variants[0].availableForSale,
            image: '',
            checkoutId: this.props.storefront.checkout !== null ? this.props.storefront.checkout.id : null
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.storefront.success !== this.props.storefront.success && nextProps.storefront.success === true) {     
            const {checkout} = nextProps.storefront
            this.setState({ 
                checkoutId: checkout.id,
            })
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onAddToCartModal = () => {
        this.props.addToCart(this.state.checkoutId, [{
            variantId: this.props.product.variants[0].shopifyId,
            quantity: this.state.quantity
        }])
        this.onCloseModal()
    }

    onClickHandle(img) {
        this.setState({ image : img} );
    }

    minusQty = () => {
        if(this.state.quantity > 1) {
            this.setState({stock: 'InStock', quantity: this.state.quantity - 1})
        }
    }
 
    plusQty = () => {
        // if(this.props.product.stock >= this.state.quantity) {
            this.setState({quantity: this.state.quantity+1})
        // }else{
            // this.setState({stock: 'Out of Stock !'})
        // }
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }


    render() {
        // , symbol, onAddToCartClicked, onAddToWishlistClicked, onAddToCompareClicked
        const {product} = this.props;
        const {open} = this.state;

        // let RatingStars = []
        // for(var i = 0; i < product.rating; i++) {
        //     RatingStars.push(<i className="fa fa-star" key={i}></i>)
        // }

        return (

                    <div className="product-box">
                        <div className="img-wrapper">
                            <div className="front">
                                <Link to={`/products/${product.handle}`} >
                                    {this.props.simpleImage ? (
                                        <img
                                            src={product.images[0].originalSrc} 
                                            className="img-fluid"
                                            style={{height: '355px'}}
                                            alt={product.title}/>
                                    ) : (
                                        <Img
                                        fluid={product.variants[0].image.localFile.childImageSharp.fluid}
                                        className="img-fluid"
                                        alt={product.title} />
                                    )}
                                </Link>
                            </div>
                            <div className="cart-info cart-wrap">
                                {this.state.availableForSale && (<button title="Add to cart" onClick={() => this.props.addToCart(this.state.checkoutId, [{
                                    variantId: product.variants[0].shopifyId,
                                    quantity: this.state.quantity
                                }])}>
                                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                </button>)}
                                {/* <a href="javascript:void(0)" title="Add to Wishlist" onClick={onAddToWishlistClicked} >
                                    <i className="fa fa-heart" aria-hidden="true"></i>
                                </a> */}
                                <button data-toggle="modal"
                                   data-target="#quick-view"
                                   title="Quick View"
                                   className="d-none d-sm-block"
                                   onClick={this.onOpenModal}><i className="fa fa-search" aria-hidden="true"></i></button>
                                {/* <Link to={`${process.env.PUBLIC_URL}/compare`} title="Compare" onClick={onAddToCompareClicked}>
                                    <i className="fa fa-refresh" aria-hidden="true"></i></Link> */}
                            </div>
                            {/*}
                            {product.variants?
                            <ul className="product-thumb-list">
                                {product.variants.map((vari, i) =>
                                    <li className={`grid_thumb_img ${(vari.images === this.state.image)?'active':''}`} key={i}>
                                        <a href="javascript:void(0)" title="Add to Wishlist">
                                            <img src={`${vari.images}`} onClick={() => this.onClickHandle(vari.images)} />
                                        </a>
                                    </li>)
                                }
                            </ul>:''} */}

                        </div>
                        <div className="product-detail">
                            <div>
                                {/* <div className="rating">
                                    {RatingStars}
                                </div> */}
                                <Link to={`/products/${product.handle}`}>
                                    <h6>{product.title}</h6>
                                </Link>
                                <h4>{getSymbolFromCurrency(product.variants[0].priceV2.currencyCode)}{Number.parseFloat(product.variants[0].priceV2.amount).toFixed(2)}
                                {product.variants[0].compareAtPriceV2 !== null && (
                                    <>
                                     - <del><span className="money">{getSymbolFromCurrency(product.variants[0].priceV2.currencyCode)}{Number.parseFloat(product.variants[0].compareAtPriceV2.amount).toFixed(2)}</span></del>
                                    </>
                                )}
                                </h4>
                                {/* {product.variants?
                                <ul className="color-variant">
                                    {product.variants.map((vari, i) => {
                                        return (
                                            <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                    })}
                                </ul>:''} */}
                            </div>
                        </div>
                    <Modal open={open} onClose={this.onCloseModal} center>
                            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                                <div className="modal-content quick-view-modal">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-6  col-xs-12">
                                                <div className="quick-view-img">
                                                {this.props.simpleImage ? (
                                                    <img
                                                        src={product.images[0].originalSrc} 
                                                        className="img-fluid"
                                                        alt={product.title}/>
                                                ) : (<Img
                                                        fluid={product.variants[0].image.localFile.childImageSharp.fluid}
                                                        className="img-fluid"
                                                        alt={product.title} />)}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 rtl-text">
                                                <div className="product-right">
                                                    <h2> {product.title} </h2>
                                                    <h3>{getSymbolFromCurrency(product.variants[0].priceV2.currencyCode)}{Number.parseFloat(product.variants[0].priceV2.amount).toFixed(2)}
                                                    {product.variants[0].compareAtPriceV2 !== null && (
                                                         <del><span className="money">{getSymbolFromCurrency(product.variants[0].priceV2.currencyCode)}{Number.parseFloat(product.variants[0].compareAtPriceV2.amount).toFixed(2)}</span></del>
                                                    )}
                                                    </h3>
                                                    {/* {product.variants?
                                                    <ul className="color-variant">
                                                        {product.variants.map((vari, i) =>
                                                            <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                                        }
                                                    </ul>:''} */}
                                                    <div className="border-product">
                                                        <h6 className="product-title">product details</h6>
                                                        <p dangerouslySetInnerHTML={{__html: product.description}}></p>
                                                    </div>
                                                    <div className="product-description border-product">
                                                        {/* {product.size?
                                                        <div className="size-box">
                                                            <ul>
                                                                {product.size.map((size, i) => {
                                                                    return <li key={i}><a href="#">{size}</a></li>
                                                                })}
                                                            </ul>
                                                        </div>:''} */}
                                                        <h6 className="product-title">quantity</h6>
                                                        <span className="instock-cls">{this.state.availableForSale ? 'InStock':'Out of Stock !'}</span>
                                                        {this.state.availableForSale && (
                                                            <>
                                                                <h6 className="product-title">quantity</h6>
                                                                <div className="qty-box">
                                                                    <div className="input-group">
                                                                    <span className="input-group-prepend">
                                                                        <button type="button" className="btn quantity-left-minus" onClick={this.minusQty} data-type="minus" data-field="">
                                                                        <i className="fa fa-angle-left"></i>
                                                                        </button>
                                                                    </span>
                                                                        <input type="text" name="quantity" value={this.state.quantity}  onChange={this.changeQty} className="form-control input-number" />
                                                                        <span className="input-group-prepend">
                                                                        <button type="button" className="btn quantity-right-plus" onClick={this.plusQty} data-type="plus" data-field="">
                                                                        <i className="fa fa-angle-right"></i>
                                                                        </button>
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="product-buttons">
                                                        <button className="btn btn-solid" onClick={() => this.onAddToCartModal()} disabled={!this.state.availableForSale}>add to cart</button>
                                                        <Link to={`/products/${product.handle}`} className="btn btn-solid">view detail</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </Modal>
                </div>
        )
    }
}

const mapStateToProps = ({cartReducer}) => {
    return {
        storefront: cartReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (checkoutId, lineItems) => dispatch(addToCart(checkoutId, lineItems))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ProductListItem);