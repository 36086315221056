import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

function LogoImage() {

    return (
        <StaticQuery
            query={graphql `
                {
                    file(base: {eq: "site-metadata.md"}) {
                      childMarkdownRemark {
                        frontmatter {
                            logo {
                                childImageSharp {
                                    fixed (width: 100,) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                      }
                    }
                  }
            `}
            render={({file}) => (
                <Link to={`/`} >
                    {/* <img src={file.childMarkdownRemark.frontmatter.logo} alt="logo" className="img-fluid col-4"/> */}
                    <Img fixed={file.childMarkdownRemark.frontmatter.logo.childImageSharp.fixed} alt="logo" className="img-fluid m-1" />
                </Link>
            )}
        />
    )
    

}

export default LogoImage;