import Client from 'shopify-buy'
import { 
    INIT_CHECKOUT_STARTED, INIT_CHECKOUT_SUCCESS, INIT_CHECKOUT_FAILED, 
    ADD_TO_CART_STARTED, ADD_TO_CART_SUCCESS, ADD_TO_CART_FAILED, 
    ADD_DISCOUNT_STARTED, ADD_DISCOUNT_SUCCESS, ADD_DISCOUNT_FAILED, 
    REMOVE_DISCOUNT_STARTED, REMOVE_DISCOUNT_SUCCESS, REMOVE_DISCOUNT_FAILED
} from '../constants/ActionTypes'

const client = Client.buildClient({
    domain: 'beardfield.com',
    storefrontAccessToken: 'f06d9cd44498ad07547714415420fe61'
});

/**
 * Init Checkout
 */

const initCheckoutStarted = () => ({
    type: INIT_CHECKOUT_STARTED
})

const initCheckoutSuccess = id => ({
    type: INIT_CHECKOUT_SUCCESS,
    payload: id
})

const initCheckoutFailed = error => ({
    type: INIT_CHECKOUT_FAILED,
    payload: error
})

function createCheckout(client, dispatch) {
    client.checkout.create().then((checkout) => {
        if (typeof localStorage !== 'undefined'){
            localStorage.setItem('checkoutId', checkout.id)
        }
        dispatch(initCheckoutSuccess(checkout))
    }).catch(error => {
        dispatch(initCheckoutFailed(error))
    })
}

export const initCheckout = () => {
    return dispatch => {
        dispatch(initCheckoutStarted())
        
        const checkoutId = typeof localStorage !== 'undefined' ? localStorage.getItem('checkoutId') : null

        if(checkoutId === null) {
            createCheckout(client, dispatch)
        } else {
            client.checkout.fetch(checkoutId).then((checkout) => {  
                if (checkout === null) {
                    createCheckout(client, dispatch)
                } else {
                    dispatch(initCheckoutSuccess(checkout))
                }
            }).catch(err => {
                localStorage.removeItem('checkoutId')
                createCheckout(client, dispatch)
            })
        }
        
    }
}


const addToCartStarted = () => ({
    type: ADD_TO_CART_STARTED
})

const addToCartSuccess = checkout => ({
    type: ADD_TO_CART_SUCCESS,
    payload: checkout
})

const addToCartFailed = error => ({
    type: ADD_TO_CART_FAILED,
    payload: error
})

export const addToCart = (checkoutid, items) => {
    return dispatch => {
        dispatch(addToCartStarted())
        client.checkout.addLineItems(checkoutid, items).then(checkout => {
            dispatch(addToCartSuccess(checkout))
        }).catch(error => {
            dispatch(addToCartFailed(error))
        })
    }
}


export const removeFromCart = (checkoutid, lineItemIdsToRemove ) => {
    return dispatch => {
        dispatch(addToCartStarted())
        client.checkout.removeLineItems(checkoutid, lineItemIdsToRemove).then(checkout => {
            dispatch(initCheckoutSuccess(checkout))
        }).catch(error => {
            dispatch(initCheckoutFailed(error))
        })
    }
}

export const updateCartItem = (checkoutId, lineItemIdsToUpdate, OperationType) => {
    return dispatch => {
        dispatch(addToCartStarted())
        client.checkout.updateLineItems(checkoutId, lineItemIdsToUpdate).then(checkout => {
            dispatch(initCheckoutSuccess(checkout))
        }).catch(error => {
            dispatch(initCheckoutFailed(error))
        })
    }
}

const addDiscountStarted = () => ({
    type: ADD_DISCOUNT_STARTED
})

const addDiscountSuccess = checkout => ({
    type: ADD_DISCOUNT_SUCCESS,
    payload: checkout
})

const addDiscountFailed = error => ({
    type: ADD_DISCOUNT_FAILED,
    payload: error
})

export const addDiscount = (checkoutId, coupon) => {
    return dispatch => {
        dispatch(addDiscountStarted())
        client.checkout.addDiscount(checkoutId, coupon).then(checkout => {
            console.log(checkout)
            if(checkout.discountApplications.length === 0) {
                dispatch(addDiscountFailed(checkout.errors))
            } else {
                dispatch(addDiscountSuccess(checkout))
            }
        }).catch(error => {
            dispatch(addDiscountFailed(error))
        })
    }
}

const removeDiscountStarted = () => ({
    type: REMOVE_DISCOUNT_STARTED
})

const removeDiscountSuccess = checkout => ({
    type: REMOVE_DISCOUNT_SUCCESS,
    payload: checkout
})

const removeDiscountFailed = error => ({
    type: REMOVE_DISCOUNT_FAILED,
    payload: error
})

export const removeDiscount = (checkoutId) => {
    return dispatch => {
        dispatch(removeDiscountStarted())
        client.checkout.removeDiscount(checkoutId).then(checkout => {
            dispatch(removeDiscountSuccess(checkout))
        }).catch(error => {
            dispatch(removeDiscountFailed(error))
        })
    }
}