import { 
    INIT_CHECKOUT_STARTED, INIT_CHECKOUT_SUCCESS, INIT_CHECKOUT_FAILED, 
    ADD_TO_CART_STARTED, ADD_TO_CART_SUCCESS, ADD_TO_CART_FAILED, 
    ADD_DISCOUNT_STARTED, ADD_DISCOUNT_SUCCESS, ADD_DISCOUNT_FAILED, 
    REMOVE_DISCOUNT_STARTED, REMOVE_DISCOUNT_SUCCESS, REMOVE_DISCOUNT_FAILED
} from "../constants/ActionTypes";

const initState = {
    checkout: null,
    success: false,
    error: null,
    newItem: false,
    applyDiscountSuccess: false,
    applyDiscountError: null,
    removeDiscountSuccess: false,
    removeDiscountError: null
}

export default function storefrontReducer(state = initState, action) {
    switch (action.type) {
        // init checkout
        case INIT_CHECKOUT_STARTED:
            return {...state, ...initState}
        case INIT_CHECKOUT_SUCCESS:
            return {...state, checkout: action.payload, success: true}
        case INIT_CHECKOUT_FAILED:
            return {...state, error: action.payload}
        // add to cart
        case ADD_TO_CART_STARTED:
            return {...state, success: false, error: null, newItem: false}
        case ADD_TO_CART_SUCCESS:
            return {...state, checkout: action.payload, success: true, newItem: true}
        case ADD_TO_CART_FAILED:
            return {...state, error: action.payload}
        // add discount
        case ADD_DISCOUNT_STARTED:
            return {...state, applyDiscountSuccess: false, applyDiscountError: null, success: false}
        case ADD_DISCOUNT_SUCCESS:
            return {...state, checkout: action.payload, applyDiscountSuccess: true, success: true}
        case ADD_DISCOUNT_FAILED:
            return {...state, applyDiscountError: action.payload}
        // remove discount
        case REMOVE_DISCOUNT_STARTED:
            return {...state, removeDiscountSuccess: false, removeDiscountError: null, success: false}
        case REMOVE_DISCOUNT_SUCCESS:
            return {...state, checkout: action.payload, removeDiscountSuccess: true, success: true}
        case REMOVE_DISCOUNT_FAILED:
            return {...state, removeDiscountError: action.payload}

        default:
    }
    return state;
}
