/**
 * Cart
 */

// init checkout
export const INIT_CHECKOUT_STARTED = 'INIT_CHECKOUT_STARTED'
export const INIT_CHECKOUT_SUCCESS = 'INIT_CHECKOUT_SUCCESS'
export const INIT_CHECKOUT_FAILED = 'INIT_CHECKOUT_FAILED'

// add to cart
export const ADD_TO_CART_STARTED = 'ADD_TO_CART_STARTED'
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS'
export const ADD_TO_CART_FAILED = 'ADD_TO_CART_FAILED'

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const INCREMENT_QTY = 'INCREMENT_QTY'
export const DECREMENT_QTY = 'DECREMENT_QTY'

// add discount
export const ADD_DISCOUNT_STARTED = 'ADD_DISCOUNT_STARTED'
export const ADD_DISCOUNT_SUCCESS = 'ADD_DISCOUNT_SUCCESS'
export const ADD_DISCOUNT_FAILED = 'ADD_DISCOUNT_FAILED'

// remove discount
export const REMOVE_DISCOUNT_STARTED = 'REMOVE_DISCOUNT_STARTED'
export const REMOVE_DISCOUNT_SUCCESS = 'REMOVE_DISCOUNT_SUCCESS'
export const REMOVE_DISCOUNT_FAILED = 'REMOVE_DISCOUNT_FAILED'