import React, {Component} from 'react'
import {Link, StaticQuery, graphql} from 'gatsby'

class PaymentMethods extends Component {

    render () {

        return <StaticQuery 
            query={query}
            render={({file}) => file.childMarkdownRemark.frontmatter.paymentMethods !== null ? (
                <div className={file.childMarkdownRemark.frontmatter.socialLinksVisible ? "footer-social pull-right":"footer-social"}>
                    <ul>
                        {file.childMarkdownRemark.frontmatter.paymentMethods.americanExpress && (
                            <li>
                                <Link to={'#'} ><i className="fab fa-cc-amex" aria-hidden="true"></i></Link>
                            </li>
                        )}
                        {file.childMarkdownRemark.frontmatter.paymentMethods.applePay && (
                            <li>
                                <Link to={'#'} ><i className="fab fa-apple" aria-hidden="true"></i></Link>
                            </li>
                        )}
                        {file.childMarkdownRemark.frontmatter.paymentMethods.dinersClub && (
                            <li>
                                <Link to={'#'} ><i className="fab fa-cc-diners-club" aria-hidden="true"></i></Link>
                            </li>
                        )}
                        {file.childMarkdownRemark.frontmatter.paymentMethods.jcb && (
                            <li>
                                <Link to={'#'} ><i className="fab fa-cc-jcb" aria-hidden="true"></i></Link>
                            </li>
                        )}
                        {file.childMarkdownRemark.frontmatter.paymentMethods.masterCard && (
                            <li>
                                <Link to={'#'} ><i className="fab fa-cc-mastercard" aria-hidden="true"></i></Link>
                            </li>
                        )}
                        {file.childMarkdownRemark.frontmatter.paymentMethods.paypal && (
                            <li>
                                <Link to={'#'} ><i className="fab fa-cc-paypal" aria-hidden="true"></i></Link>
                            </li>
                        )}
                        {file.childMarkdownRemark.frontmatter.paymentMethods.visa && (
                            <li>
                                <Link to={'#'} ><i className="fab fa-cc-visa" aria-hidden="true"></i></Link>
                            </li>
                        )}
                    </ul>
                </div>
            ): ''}
        />
    }
}

const query = graphql`
{
    file(base: {eq: "footer.md"}) {
        childMarkdownRemark {
            frontmatter {
                paymentMethods {
                    americanExpress
                    applePay
                    dinersClub
                    jcb
                    masterCard
                    paypal
                    visa
                }
                socialLinksVisible
            }
        }
    }
}
`

export default PaymentMethods