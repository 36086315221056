import React, {Component} from 'react'
import {Provider} from 'react-redux'
import store from '../store'
import {initCheckout} from '../actions/cart'

import Header from '../components/header'
import Footer from '../components/footer'

class Layout extends Component {
 
    componentWillMount() {
        if (typeof window !== 'undefined') {
            store.dispatch(initCheckout())
        }
        if(typeof window !== 'undefined') {
            window.addEventListener('scroll', this.handleScroll);
        }
    }
    componentWillUnmount() {
        if(typeof window !== 'undefined') {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
    handleScroll = () => {
        if (document.documentElement.scrollTop > 600) {
            document.querySelector(".tap-top").style = "display: block";
        } else {
            document.querySelector(".tap-top").style = "display: none";
        }
    }
    clickToTop(){
        if (typeof window !== 'undefined') {
            window.scroll({top: 0, left: 0, behavior: 'smooth' })
        }
    }

    render = () => {
        let tap_to_top = {display: 'none'}
        return (
        <Provider store={store}>
            <Header />
            {this.props.children}
            <Footer />
            <div className="tap-top" onClick={this.clickToTop} style={tap_to_top}>
                <div>
                    <i className="fa fa-angle-double-up"></i>
                </div>
            </div>
        </Provider>
    )}

}

export default Layout